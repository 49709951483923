var inputTypeValidations = {
  google_analytics: /^(g-[\w\d]+)$/i,
  google_tag_manager: /^GTM-[\w\d]{7}$/i, // Format type : GTM-XXXXXXX where X can be alphanumeric
  color: /^(#(?=[a-f0-9]*$)(?:.{3}|.{6})|transparent)$/i,
  dimensions: /^\d{1,4}(px|pt|em|rem|%)$/,
  url: /^(https?|www|mailto|\/)/
};

var validationRules = {
  'content[general][google_analytics_token]': {
    pattern: inputTypeValidations.google_analytics
  },
  'content[general][google_tag_manager_token]': {
    pattern: inputTypeValidations.google_tag_manager
  },
  'content[general][webtrends_url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][window_icon]': {
    pattern: inputTypeValidations.url
  },
  'content[general][styles][max_width]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[header]links[][url]': {
    pattern: inputTypeValidations.url
  },
  'content[header][logo][src]': {
    pattern: inputTypeValidations.url
  },
  'content[header][styles][logo_height]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[header][styles][logo_width]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[header][logo][url]': {
    pattern: inputTypeValidations.url
  },
  'content[header][styles][display_name_fg_color]': {
    pattern: inputTypeValidations.color
  },
  'content[header][styles][display_name_size]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[header][styles][bg_color]': {
    pattern: inputTypeValidations.color
  },
  'content[header][styles][bg_color_secondary]': {
    pattern: inputTypeValidations.color
  },
  'content[header][styles][fg_color]': {
    pattern: inputTypeValidations.color
  },
  'content[footer]links[][url]': {
    pattern: inputTypeValidations.url
  },
  'content[header]links[]links[][url]': {
    pattern: inputTypeValidations.url
  },
  'content[footer][logo][src]': {
    pattern: inputTypeValidations.url
  },
  'content[footer][styles][logo_height]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[footer][styles][logo_width]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[footer][logo][url]': {
    pattern: inputTypeValidations.url
  },
  'content[footer][styles][display_name_fg_color]': {
    pattern: inputTypeValidations.color
  },
  'content[footer][styles][display_name_size]': {
    pattern: inputTypeValidations.dimensions
  },
  'content[footer][styles][bg_color]': {
    pattern: inputTypeValidations.color
  },
  'content[footer][styles][fg_color]': {
    pattern: inputTypeValidations.color
  },
  'content[general][social_shares][facebook][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][twitter][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][youtube][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][linked_in][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][flickr][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][instagram][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][tumblr][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][pinterest][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][yammer][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][vimeo][url]': {
    pattern: inputTypeValidations.url
  },
  'content[general][social_shares][github][url]': {
    pattern: inputTypeValidations.url
  }
};

// Note: This needs to be a function rather than an object because $.t is not defined on load.
var validationMessages = function() {
  return {
    'content[general][google_analytics_token]': $.t('screens.admin.site_appearance.tabs.general.fields.google_analytics_token.error'),
    'content[general][google_tag_manager_token]': $.t('screens.admin.site_appearance.tabs.general.fields.google_tag_manager_token.error'),
    'content[header][styles][logo_height]': $.t('screens.admin.site_appearance.tabs.header.fields.header_logo_height.error'),
    'content[header][styles][logo_width]': $.t('screens.admin.site_appearance.tabs.header.fields.header_logo_width.error'),
    'content[footer][styles][logo_height]': $.t('screens.admin.site_appearance.tabs.footer.fields.footer_logo_height.error'),
    'content[footer][styles][logo_width]': $.t('screens.admin.site_appearance.tabs.footer.fields.footer_logo_width.error')
  };
};

var $siteAppearanceForm = $('form#site_appearance_form');
let updateUpdateButton;

$(document).ready(function() {
  // EN-12175: If using custom header/footer, only show a Preview button on Site Appearance.
  if ($('.site-appearance').hasClass('custom')) {
    $('button#site_appearance_preview').on('click', function(e) {
      e.preventDefault();
      document.cookie = 'socrata_site_chrome_preview=true;path=/';
      location.reload();
    });

    return;
  }

  var curTab = getActiveTabId();
  // Show appropriate tab given the url hash. Ex: /site_appearance#tab=general
  showTab(curTab);

  inputValidation();

  // Change active tab and tab-content on click
  $('ul.tabs li').on('click', function() {
    curTab = $(this).data('tab-id');
    showTab(curTab);
  });

  // EN-8454: On back button click that changes only the url fragment,
  // render the correct tab.
  window.onpopstate = function() {
    var curTabOnPopState = getActiveTabId();
    if (curTab !== curTabOnPopState) {
      curTab = curTabOnPopState;
      showTab(curTab);
    }
  };

  // Submit the form when save button is clicked
  $('button#site_appearance_save').on('click', function() {
    if ($siteAppearanceForm.length && $siteAppearanceForm.valid()) {
      preSubmitLinkCleansing();
      $siteAppearanceForm.removeAttr('target');
      $siteAppearanceForm.find('input#stage').remove();
      $siteAppearanceForm.trigger('submit');
    }
  });

  // Secret button only visible to superadmins that allows saving the configuration without activation.
  $('button#save_without_activation').on('click', function() {
    if ($siteAppearanceForm.length && $siteAppearanceForm.valid()) {
      preSubmitLinkCleansing();
      $('#enable_activation').attr('disabled', true);
      $siteAppearanceForm.removeAttr('target');
      $siteAppearanceForm.find('input#stage').remove();
      $siteAppearanceForm.trigger('submit');
    }
  });

  // Submit the preview form when preview button is clicked
  $('button#site_appearance_preview').on('click', function(e) {
    e.preventDefault();
    if ($siteAppearanceForm.length && $siteAppearanceForm.valid()) {
      preSubmitLinkCleansing();
      $siteAppearanceForm.attr('target', '_blank');
      var $stage = $siteAppearanceForm.find('input#stage');
      if (!$stage.exists()) {
        $stage = $('<input type="hidden" id="stage" name="stage" />');
        $siteAppearanceForm.append($stage);
      }
      $stage.val('draft');
      $siteAppearanceForm.trigger('submit');
      var intervalCounter = 0;
      var checkCookieInterval = setInterval(function() {
        intervalCounter++;
        if (document.cookie.indexOf('socrata_site_chrome_preview=true') > -1) {
          clearInterval(checkCookieInterval);
          window.location.reload();
        }
        if (intervalCounter > 20) {
          clearInterval(checkCookieInterval);
          alert($.t('screens.admin.site_appearance.preview_failure'));
        }
      }, 500);
    }
  });

  var onLoadOrClickingSigninSignoutCheckbox = function() {
    var value = $('#content_general_show_signin_signout').attr('checked');
    var $knockonEffects = $('#content_general_show_signup, #content_general_show_profile');
    if (value) {
      $knockonEffects.prop('disabled', false);
    } else {
      $knockonEffects.removeAttr('checked').
      prop('disabled', true);
    }
  };
  onLoadOrClickingSigninSignoutCheckbox();
  $('#content_general_show_signin_signout').on('click change', onLoadOrClickingSigninSignoutCheckbox);

  $('.flyout-target').on('mouseenter', function(e) {
    $(e.target).closest('.flyout-container').find('.flyout').removeClass('flyout-hidden');
  }).on('mouseleave', function(e) {
    $(e.target).closest('.flyout-container').find('.flyout').addClass('flyout-hidden');
  });

  sortableListOfLinks();

  // Colors
  const VALID_HEX_CODE_PATTERN = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;
  const scale = (num, inMin, inMax, outMin, outMax) =>
    (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;

  const getArrayFromSerializedTextArea = (selector) => {
    const value = $(selector).val();
    let array = [];

    try {
      array = JSON.parse(value);
    } catch (error) { /* ignorable */ }

    return array;
  };

  // Update bar
  updateUpdateButton = () => {
    const hasValidPickerHexCodes = hasValidColorPickerHexCodes();
    const hasValidPaletteHexCodes = hasValidColorPaletteHexCodes();
    const hasUniquePaletteNames = hasUniqueColorPaletteNames();
    const $saveButtons = $('#site_appearance_save, #site_appearance_preview, #site_appearance_activate, #save_without_activation');

    if (!hasValidPickerHexCodes || !hasValidPaletteHexCodes || !hasUniquePaletteNames) {
      if ($('div.flash.notice.error.colors').length <= 0) {
        $('#noticeContainer').append($('<div class="flash notice error colors">').
          text($.t('screens.admin.site_appearance.error_invalid_hex_codes')));
      }
      $saveButtons.addClass('error');
      $('#site_appearance_save').attr('disabled', true);
      $('#site_appearance_preview').attr('disabled', true);
    } else {
      $('div.flash.notice.error.colors').remove();
      $saveButtons.removeClass('error');
      $('#site_appearance_save').removeAttr('disabled');
      $('#site_appearance_preview').removeAttr('disabled');
    }
  };

  // Picker
  const generateColorPickerPreview = () => {
    const text = $('#color-picker-hex-codes-textarea').val();
    const colors = _.reject(text.split(/[,;\s]+/g), (item) => _.isEmpty(item));
    const swatches = _.map(colors, (color) => $(`<span style="background-color:${color}"></span>`));

    $('#color-picker-preview-container').empty().append(swatches);
  };

  const getColorPickerHexCodes = () => {
    return getArrayFromSerializedTextArea('#color-picker-serialized-textarea');
  };

  const hasValidColorPickerHexCodes = () => {
    const pickerHexCodes = getColorPickerHexCodes();

    for (let i = 0; i < pickerHexCodes.length; i++) {
      const hexCode = pickerHexCodes[i];
      if (!VALID_HEX_CODE_PATTERN.test(hexCode)) {
        return false;
      }
    }

    return true;
  };

  const onInputPickerHexCodes = () => {
    serializeColorPickerHexCodes();
    updateColorControls();
  };

  const serializeColorPickerHexCodes = () => {
    const text = $('#color-picker-hex-codes-textarea').val();
    const hexCodes = _.reject(text.split(/[,;\s]+/g), (item) => _.isEmpty(item));
    const hexCodesString = JSON.stringify(hexCodes);
    $('#color-picker-serialized-textarea').val(hexCodesString);
  };

  const updateColorPickerHexCodesTextArea = ({ setInputValues }) => {
    const hasValidHexCodes = hasValidColorPickerHexCodes();
    $('#color-picker-hex-codes-textarea').toggleClass('invalid-content', !hasValidHexCodes);

    if (setInputValues) {
      const hexCodes = getColorPickerHexCodes();
      const hexCodesString = hexCodes.join(' ');
      $('#color-picker-hex-codes-textarea').val(hexCodesString);
    }
  };

  // Palettes
  const generateColorPalettePreview = () => {
    const text = $('#color-palettes-hex-codes-textarea').val();
    const colors = _.reject(text.split(/[,;\s]+/g), (item) => _.isEmpty(item));
    const bars = _.map(colors, (color, index) => {
      const invertedIndex = colors.length - index;
      const input = (invertedIndex * invertedIndex) / (colors.length * colors.length);
      const width = scale(input, 0, 1, 5, 100);

      return $(`<span style="background-color:${color};width:${width}%"></span>`);
    });

    $('#color-palette-preview-container').empty().append(bars);
  };

  const getColorPalette = (index) => {
    return (index >= 0) && (index < palettes.length) ?
      palettes[index] :
      null;
  };

  const getColorPaletteName = () => {
    const defaultName = _.get(window, 'translations.screens.admin.site_appearance.tabs.colors.fields.color_palettes.new_palette');
    let i = 1;
    let name = `${defaultName} ${i}`;

    while (_.some(palettes, (palette) => palette.name === name)) {
      name = `${defaultName} ${i++}`;
    }

    return name;
  };

  // Returns an object of the form:
  // { 'Palette 1': true } <= 'Palette 1' is a unique name
  // { 'Palette 2': false } <= 'Palette 2' is not a unique name
  const getColorPaletteNamesUniquenessTable = () => {
    let o = {};
    _.each(palettes, (palette) => {
      o[palette.name] = _.isNil(o[palette.name]);
    });
    return o;
  };

  const getColorPalettes = () => {
    return getArrayFromSerializedTextArea('#color-palettes-serialized-textarea');
  };

  const getNextColorPaletteIndex = () => {
    if (selectedColorPaletteIndex < palettes.length) {
      return selectedColorPaletteIndex;
    }

    const index = selectedColorPaletteIndex - 1;
    if ((index >= 0) && (index < palettes.length)) {
      return index;
    }

    return -1;
  };

  const hasUniqueColorPaletteNames = () => {
    const uniquenessTable = getColorPaletteNamesUniquenessTable();
    return _.every(uniquenessTable, (value) => value);
  };

  const hasValidColorPaletteHexCodes = () => {
    const colorPalettes = getColorPalettes();
    for (let i = 0; i < colorPalettes.length; i++) {
      const palette = colorPalettes[i];

      for (let j = 0; j < palette.hexCodes.length; j++) {
        const hexCode = palette.hexCodes[j];
        if (!VALID_HEX_CODE_PATTERN.test(hexCode)) {
          return false;
        }
      }
    }

    return true;
  };

  const onClickAddPalette = () => {
    const name = getColorPaletteName();
    palettes.push({ hexCodes: [], name });
    selectedColorPaletteIndex = palettes.length - 1;

    serializeColorPalettes();
    updateColorControls({ setInputValues: true});
  };

  const onClickDeletePalette = () => {
    if ((selectedColorPaletteIndex >= 0) && (selectedColorPaletteIndex < palettes.length)) {
      palettes.splice(selectedColorPaletteIndex, 1);
      selectedColorPaletteIndex = getNextColorPaletteIndex();

      serializeColorPalettes();
      updateColorControls({ setInputValues: true });
    }
  };

  const onInputPaletteHexCodes = () => {
    const palette = getColorPalette(selectedColorPaletteIndex);
    if (palette) {
      const text = $('#color-palettes-hex-codes-textarea').val();
      const hexCodes = _.reject(text.split(/[,;\s]+/g), (item) => _.isEmpty(item));
      _.set(palette, 'hexCodes', hexCodes);
    }

    serializeColorPalettes();
    updateColorControls();
  };

  const onInputPaletteName = () => {
    const palette = getColorPalette(selectedColorPaletteIndex);
    if (palette) {
      let name = $('#color-palettes-palette-name-input').val().trim();
      if (_.isEmpty(name)) {
        name = getColorPaletteName();
      }
      _.set(palette, 'name', name);
    }

    serializeColorPalettes();
    updateColorControls();
  };

  const serializeColorPalettes = () => {
    $('#color-palettes-serialized-textarea').text(JSON.stringify(palettes));
  };

  const updateColorPaletteEditPanel = () => {
    const palette = getColorPalette(selectedColorPaletteIndex);
    if (palette) {
      $('#color-palette-edit-controls').show();
    } else {
      $('#color-palette-edit-controls').hide();
    }
  };

  const updateColorPaletteList = () => {
    const uniquenessTable = getColorPaletteNamesUniquenessTable();
    const getClass = (palette, index) => {
      const hasInvalidHexCodes = _.some(palette.hexCodes, (hexCode) => !VALID_HEX_CODE_PATTERN.test(hexCode));
      const hasNonUniquePaletteName = !uniquenessTable[palette.name];
      const isSelected = (index === selectedColorPaletteIndex);

      if (isSelected) {
        return 'selected';
      } else if (hasInvalidHexCodes || hasNonUniquePaletteName) {
        return 'invalid-content';
      } else {
        return null;
      }
    };

    const elements = _.map(
      palettes,
      (palette, index) => $('<li>').
        attr('class', getClass(palette, index)).
        text(palette.name).
        on('click', function() {
          selectedColorPaletteIndex = $(this).index();
          updateColorControls({ setInputValues: true });
        })
      );

    $('#color-palettes-list').empty().append(elements);
  };

  const updateColorPaletteNameInput = ({ setInputValues }) => {
    const palette = getColorPalette(selectedColorPaletteIndex);
    const $input = $('#color-palettes-palette-name-input');
    if (palette) {
      const uniquenessTable = getColorPaletteNamesUniquenessTable();
      const hasNonUniquePaletteName = !uniquenessTable[palette.name];
      $input.toggleClass('invalid-content', hasNonUniquePaletteName);
      $input.attr('placeholder', palette.name);
    } else {
      $input.removeAttr('placeholder');
    }

    if (setInputValues) {
      const value = palette ? _.get(palette, 'name', '') : '';
      $input.val(value);
    }
  };

  const updateColorPaletteHexCodesTextArea = ({ setInputValues }) => {
    const palette = getColorPalette(selectedColorPaletteIndex);
    const $textarea = $('#color-palettes-hex-codes-textarea');
    if (palette) {
      const hasInvalidHexCodes = _.some(palette.hexCodes, (hexCode) => !VALID_HEX_CODE_PATTERN.test(hexCode));
      $textarea.toggleClass('invalid-content', hasInvalidHexCodes);
    } else {
      $textarea.removeClass('invalid-content');
    }

    if (setInputValues) {
      const value = palette ? _.get(palette, 'hexCodes', []).join(' ') : '';
      $textarea.val(value);
    }
  };

  const updateColorPaletteDeleteLink = () => {
    if (selectedColorPaletteIndex == -1) {
      $('#color-palettes-delete-palette-link').hide();
    } else {
      $('#color-palettes-delete-palette-link').show();
    }
  };

  // All
  const updateColorControls = ({ setInputValues } = {}) => {
    // Update bar
    updateUpdateButton();

    // Picker
    updateColorPickerHexCodesTextArea({ setInputValues });
    generateColorPickerPreview();

    // Palettes
    updateColorPaletteList();
    updateColorPaletteDeleteLink();
    updateColorPaletteEditPanel();
    updateColorPaletteNameInput({ setInputValues });
    updateColorPaletteHexCodesTextArea({ setInputValues });
    generateColorPalettePreview();
  };

  const initColorControls = () => {
    $('#color-palettes-add-palette-button').on('click', onClickAddPalette);
    $('#color-palettes-delete-palette-link').on('click', onClickDeletePalette);
    $('#color-palettes-hex-codes-textarea').on('input', onInputPaletteHexCodes);
    $('#color-palettes-palette-name-input').on('input', onInputPaletteName);
    $('#color-picker-hex-codes-textarea').on('input', onInputPickerHexCodes);

    updateColorControls({ setInputValues: true });
  };

  let palettes = getColorPalettes();
  let selectedColorPaletteIndex = (palettes.length > 0) ? 0 : -1;

  initColorControls();
});

$('.activate-site-appearance').on('click', function() {
  if ($siteAppearanceForm.length && $siteAppearanceForm.valid()) {
    preSubmitLinkCleansing();
    $('#enable_activation').attr('disabled', false);
    $siteAppearanceForm.removeAttr('target');
    $siteAppearanceForm.find('input#stage').remove();
    $siteAppearanceForm.trigger('submit');
  }
});

// Figure out which tab is active (current) and get its id
function getActiveTabId() {
  var urlHash = document.location.hash.substring(1);
  var activeTabId = urlHash.substr(urlHash.indexOf('tab=')).split('&')[0].split('=')[1];
  if (activeTabId) {
    return activeTabId;
  } else {
    // No tab specified, show first tab
    return _.get($('ul.tabs li').first().data(), 'tabId', 'general');
  }
}

function showTab(tabId) {
  $('ul.tabs li, .tab-content').removeClass('current');
  $(`.tab-content[data-tab-id="${tabId}"], .tab-link[data-tab-id="${tabId}"]`).addClass('current');
  $(`.tab-content[data-tab-id="${tabId}"], .tab-link[data-tab-id="${tabId}"]`).addClass('current');

  // Replace the anchor part so that we reload onto the same tab we submit from.
  $siteAppearanceForm.attr('action', $siteAppearanceForm.attr('action').replace(/#tab=.*/, '#tab=' + tabId));
  updatePageControlsForActiveTab(tabId);
}

function updatePageControlsForActiveTab(tabId) {
  $('.page-controls a[href*="#"]').attr('href', '#tab=' + tabId);
}

// Displays a notification to the user if they type invalid input.
// Uses jQuery Validate plugin: public/javascripts/plugins/jquery.validate.js
function inputValidation() {
  $siteAppearanceForm.validate({
    rules: validationRules,
    messages: validationMessages(),
    onkeyup: false,
    focusInvalid: false,
    // Validator ignores :hidden by default, which ignores all the hidden tabs.
    // We want it to care about the hidden tabs, so using a dummy selector for it to operate on.
    ignore: '.irrelevant',
    errorPlacement: function($error, $element) {
      $error.appendTo($element.parent());
    },
    errorClass: 'site-appearance-input-error'
  });

  toggleSaveButton();

  $siteAppearanceForm.find('input').on('blur', toggleSaveButton);
}

// Toggle "save" button if form is valid/invalid
function toggleSaveButton() {
  var $saveButtons = $('#site_appearance_save, #site_appearance_preview, #site_appearance_activate, #save_without_activation');
  if ($siteAppearanceForm.valid()) {
    if (_.isFunction(updateUpdateButton)) {
      updateUpdateButton();
    } else {
      $saveButtons.removeClass('error');
    }
  } else {
    $saveButtons.addClass('error');
  }
}

$('.confirm-reload').on('click', function() {
  var href = window.location.href; // store href with the hash of the current tab
  var confirmation = confirm('Cancelling will reload the page and erase any current changes.');
  if (confirmation) {
    // `confirm` wipes away the hash after we set the new location. We need to use _.defer which
    // lets the location.href run a frame after `confirm` wipes the hash out of the URL.
    _.defer(function() {
      window.location.href = href;
      location.reload();
    });
  } else {
    // If the user clicks "Cancel" in the `confirm`, it still wipes away the hash. Replace it.
    _.defer(function() {
      window.location.href = href;
    });
  }
});

$('.dropdown-option').on('click', function() {
  // Update the hidden dropdown input value
  var selectedValue = $(this).attr('value');
  $(this).closest('div.dropdown').siblings('.hidden-dropdown-input').value(selectedValue);

  // Remove the placeholder class on the title if it's present
  $(this).closest('div.dropdown').find('span.placeholder').removeClass('placeholder');
});

// See also platform-ui/frontend/public/javascripts/screens/all-screens.js
// See also platform-ui/common/js_utils/getLocale.js
function currentLocale() {
  return _.get(window.blist, 'locale',
    _.get(window.serverConfig, 'locale',
      _.get(window.socrata, 'locale',
        _.get(window.socrataConfig, 'locales.currentLocale', 'en'))));
}

$('.copyright-checkbox').on('click', function() {
  var $textbox = $('#copyright-notice-text');
  $textbox.prop('disabled', !this.checked);
});

/*
  listOfLinks - a sortable list of text inputs.
*/

// Mapping of different input types to their 'name' attributes. Note that the name attribute is what
// determines where the data is saved into the JSON blob.
var listOfLinksInputNames = function(contentKey, id) {
  return {
    hiddenLabelInput: {
      linkRow: `content[${contentKey}]links[][key]`,
      linkMenu: `content[${contentKey}]links[][key]`,
      childLinkRow: `content[${contentKey}]links[]links[][key]`
    },
    localizedLabelInput: {
      linkRow: `content[locales][${currentLocale()}][${contentKey}]links[${id}]`,
      linkMenu: `content[locales][${currentLocale()}][${contentKey}]links[${id}]`,
      childLinkRow: `content[locales][${currentLocale()}][${contentKey}]links[${id}]`
    },
    urlInput: {
      linkRow: `content[${contentKey}]links[][url]`,
      childLinkRow: `content[${contentKey}]links[]links[][url]`
    }
  };
};

// Called after a link/menu is added or removed. If the count of top-level links/menus is >=
// the limit, then we disable the buttons to add new top-level links/menus.
function checkTopLevelLinkCount($listOfLinks) {
  const topLevelLinkLimit = 15; // Max number of top level links and menus
  var topLevelLinkCount = $listOfLinks.
    find('.links-and-menus').
    // `children` to make sure we aren't including nested links inside `.link-menu`s
    children('.link-row, .link-menu').
    not('.default').
    length;
  if (topLevelLinkCount < topLevelLinkLimit) {
    $listOfLinks.children('.add-new-link-row, .add-new-link-menu').prop('disabled', false);
  } else {
    $listOfLinks.children('.add-new-link-row, .add-new-link-menu').prop('disabled', true);
  }
}

$('.list-of-links').on('click', '.add-new-link-row', function() {
  var isChildLink = $(this).parent().hasClass('link-menu');
  var $listOfLinks = $(this).closest('.list-of-links');

  var defaultLinkRowSelector = isChildLink ? '.link-row.default.child' : '.link-row.default:not(.child)';
  var $defaultLinkRow = $listOfLinks.find(defaultLinkRowSelector);
  var $newLinkRow = $defaultLinkRow.clone();

  $newLinkRow.removeClass('default');

  var $urlInput = $newLinkRow.find('.url-input');

  // EN-11064: Unique id so jquery validate plugin can hook onto individual url-inputs
  var randomId = Math.random().toString(36).slice(2);
  $urlInput.attr('id', randomId);

  // EN-16394: Make URL inputs required.
  $urlInput.attr('required', 'required');

  // Append newLinkMenu to end (of either top level or inside a menu).
  $(this).siblings('.links-and-menus, .child-links').append($newLinkRow);

  checkTopLevelLinkCount($listOfLinks);
  inputValidation();
});

$('.list-of-links').on('click', '.remove-link-row', function() {
  var $listOfLinks = $(this).closest('.list-of-links');
  $(this).closest('.link-row').remove();
  checkTopLevelLinkCount($listOfLinks);
  inputValidation();
});

$('.list-of-links').on('click', '.add-new-link-menu', function() {
  var $defaultLinkMenu = $(this).siblings('.links-and-menus').find('.link-menu.default');
  var $newLinkMenu = $defaultLinkMenu.clone();

  $newLinkMenu.removeClass('default');
  // Append new link menu after all other top-level links and menus
  $(this).siblings('.links-and-menus').
    // Use `.children` instead of `.find` to make sure we are only getting top-level link-rows
    children('.link-menu, .link-row').
    not('.default').
    last().
    after($newLinkMenu);
  // Create new link-row inside new menu.
  $newLinkMenu.find('.add-new-link-row').trigger('click');
});

// Remove menu and move its child links to top-level links.
$('.list-of-links').on('click', '.remove-link-menu', function() {
  var $listOfLinks = $(this).closest('.list-of-links');
  var $childLinks = $(this).siblings('.child-links').find('.link-row');
  $childLinks.each(function() {
    moveChildLinkToTopLevelLink($childLinks);
  });

  $(this).closest('.link-menu').replaceWith($childLinks);
  checkTopLevelLinkCount($listOfLinks);
});

// EN-9029: Allow users to upload images as assets
$('.form-field-image-input-button').on('click', function() {
  $(this).siblings('.form-field-image-hidden-input').trigger('click');
});

$('.form-field-image-hidden-input').on('change', function() {
  var imageFile = this.files[0];
  if (imageFile && /^image/.test(imageFile.type)) {
    var formData = new FormData();
    formData.append('file', imageFile);

    var $defaultButton = $(this).siblings('button.form-field-image-input-button');
    var $busyButton = $(this).siblings('button.btn-busy');
    $defaultButton.hide();
    $busyButton.show();

    $.ajax({
      url: '/api/assets',
      type: 'POST',
      data: formData,
      context: this,
      contentType: false,
      processData: false,
      timeout: 30000,
      success: function(json) {
        var response = JSON.parse(json);
        var relativeUrl = `/api/assets/${response.id}?${response.nameForOutput}`;
        $(this).siblings('.upload-failed').hide(); // hide any previous errors
        $(this).siblings('.form-field-url-input').val(relativeUrl);
        $busyButton.hide();
        $defaultButton.show();
      },
      error: function(e) {
        console.log(e);
        $(this).siblings('.upload-failed').show();
        $busyButton.hide();
        $defaultButton.show();
        // Clear the value of the hidden input, so if the user tries again to upload the same image,
        // we detect the `change` event.
        this.value = '';
      }
    });
  }
});

// Before submit, reorder the indices of the present links and menus to reflect the current
// appearance. Also remove any empty links to prevent them from being saved to the config.
function preSubmitLinkCleansing() {
  $siteAppearanceForm.find('.list-of-links').each(function(i, listOfLinks) {
    var contentKey = $(listOfLinks).data('contentKey');
    var $linkRows = $(listOfLinks).children('.links-and-menus').children('.link-row');
    var $presentLinkRows = $linkRows.filter(function() {
      return $(this).find(`input[name="${listOfLinksInputNames(contentKey).urlInput.linkRow}"]`).value();
    });

    // Top level links: add the link index to their input names and values
    $presentLinkRows.each(function(index, link) {
      var linkId = `link_${index}`;
      $(link).find('.hidden-label-input').val(linkId);

      var linkLocaleId = listOfLinksInputNames(contentKey, linkId).localizedLabelInput.linkRow;
      $(link).find('.localized-label-input').attr('name', linkLocaleId);
    });

    // Link Menus: add menu index to input names and values
    $(listOfLinks).children('.links-and-menus').children('.link-menu').not('.default').each(
      function(menuIndex, menu) {
        var menuId = `menu_${menuIndex}`;
        $(menu).find('.hidden-label-input').val(menuId);

        var menuLocaleId = listOfLinksInputNames(contentKey, menuId).localizedLabelInput.linkMenu;
        $(menu).find('.localized-label-input').attr('name', menuLocaleId);

        // Child links: add the menu + childLink index to their input names and values
        var $childLinks = $(menu).children('.child-links').children('.link-row');
        var $presentChildLinks = $childLinks.filter(function() {
          return $(this).find(`input[name="${listOfLinksInputNames(contentKey).urlInput.childLinkRow}"]`).value();
        });

        $presentChildLinks.each(function(childLinkIndex, childLink) {
          var childLinkId = `${menuId}_link_${childLinkIndex}`;
          $(childLink).find('.hidden-label-input').val(childLinkId);

          var childLinkLocaleId = `${listOfLinksInputNames(contentKey, childLinkId).localizedLabelInput.childLinkRow}`;
          $(childLink).find('.localized-label-input').attr('name', childLinkLocaleId);
        });
      }
    );

    // Remove non-present link-rows and menus
    $linkRows.not($presentLinkRows).remove();
    $(listOfLinks).children('.links-and-menus').children('.link-menu.default').remove();
  });
}

// Change data structure (name attribute) of child links to match that of top-level links.
function moveChildLinkToTopLevelLink($link) {
  var tab = getActiveTabId();
  $link.removeClass('child');
  $link.find('.hidden-label-input').attr('name', listOfLinksInputNames(tab).hiddenLabelInput.linkRow);
  $link.find('.url-input').attr('name', listOfLinksInputNames(tab).urlInput.linkRow);
  $link.find('.hidden-label-input').attr('name', listOfLinksInputNames(tab).hiddenLabelInput.linkRow);
}

// Change data structure (name attribute) of links to match that of child links.
function moveTopLevelLinkToChildLink($link) {
  var tab = getActiveTabId();
  $link.addClass('child');
  $link.find('.hidden-label-input').attr('name', listOfLinksInputNames(tab).hiddenLabelInput.childLinkRow);
  $link.find('.url-input').attr('name', listOfLinksInputNames(tab).urlInput.childLinkRow);
  $link.find('.hidden-label-input').attr('name', listOfLinksInputNames(tab).hiddenLabelInput.childLinkRow);
}

function linkIsChildLink($link) {
  return $link.parent().hasClass('child-links');
}

// jQuery UI Sortable list of links
// https://jqueryui.com/sortable/
function sortableListOfLinks() {
  $('.list-of-links').each(function(i, listOfLinks) {
    $(listOfLinks).find('.links-and-menus').sortable({
      connectWith: '.link-row',
      items: '.link-row',
      revert: 100,
      update: function(event, ui) {
        if (linkIsChildLink(ui.item)) {
          moveTopLevelLinkToChildLink(ui.item);
        } else {
          moveChildLinkToTopLevelLink(ui.item);
        }
      }
    });
  });
}
